import { useState } from "react";
import { Modal, Table, Dropdown } from "react-bootstrap";
import queryString from "query-string";
import { useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import { format, parse } from "date-fns";
import CachedIcon from "mdi-react/CachedIcon";

import useDebounce from "../../utils/hooks";
import {
  paginationOptions,
  scrollToTop,
  fetchActionsUtil,
} from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ModalLoader from "../utils/ModalLoader";
import RsDateRangePicker from "./../utils/RsDateRangePicker";
import NoTableItem from "./../utils/NoTableItem";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";

export default function SalesHistoryModal({ onHide, show, sales }) {
  const initialFilterParams = {
    showAll: false,
  };

  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);

  const { data = { history: [] }, isLoading, refetch } = useQuery(
    ["GET_SALES_HISTORY", { debouncedqueryParams, sales }],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/invoice/sales-history/${
          sales.ID
        }?${queryString.stringify(debouncedqueryParams)}`,
        "GET"
      ),
    {
      enabled: true,
      keepPreviousData: false,
    }
  );

  //   console.log(data);
  //   const handleSearchQueryChange = (e) => {
  //     setQueryParams({
  //       ...queryParams,
  //       page: 1,
  //       [e.target.name]:
  //         e.target.type === "checkbox" ? e.target.checked : e.target.value,
  //     });
  //   };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      dialogClassName="requisition-details-modal approve-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Supply History</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 d-flex flex-column">
        <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4 h6">
          <div>
            <h2>
              {sales?.Item_Name} / {sales?.Bar_Code}
              <button onClick={() => refetch()} className="btn text-primary">
                <CachedIcon />
              </button>
            </h2>
            <p className="text-muted">Total Quantity Purchase: {sales.QTY}</p>
          </div>

          <h2>{sales?.ShipTo}</h2>

          <RsDateRangePicker
            placement="bottomEnd"
            defaultValue={
              queryParams.startDate && queryParams.endDate
                ? [
                    parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                    parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                  ]
                : []
            }
            onClean={() => clearDateRange()}
            onOk={(date) => filterByDateRange(date)}
          />

          {/* <Form.Group>
            <Form.Check
              type="switch"
              label="Show All"
              name="showAll"
              className="ga-form-check fs-6"
              checked={queryParams.showAll}
              onChange={handleSearchQueryChange}
            />
          </Form.Group> */}
        </div>

        <div className="content px-1 pb-4 pe-2 flex-grow-1">
          <Table borderless responsive striped className="product-table">
            <thead className="sticky border-bottom">
              <tr>
                <th />
                <th className="text-center">Date</th>
                <th className="text-center">Quantity Supplied</th>
                <th className="text-center">Quantity Shipped</th>
                <th className="text-center">Remaining Quantity</th>
              </tr>
            </thead>

            <tbody className="blue-hover">
              {data?.history &&
                data?.history?.map((el, index) => (
                  <tr key={index}>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          //  className="bg-white border-0"
                          bsPrefix="print more"
                        >
                          <DotsVerticalIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className="dropdown-with-icons"
                        >
                          <Dropdown.Item as="div">
                            <a
                              href={`${backendUrl}/api/invoice/pdf/single-waybill/${
                                el.TransID
                              }`}
                              target="blank"
                            >
                              Open Waybil
                            </a>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td className="text-center">
                      {el.Date_Log
                        ? format(new Date(el.Date_Log), "dd MMM, yyyy HH:mm:ss")
                        : "..."}
                    </td>
                    <td className="text-center">{el.already_supplied_QTY}</td>
                    <td className="text-center">{el.supply_QTY}</td>
                    <td className="text-center">{el.balance_QTY}</td>
                  </tr>
                ))}
            </tbody>
          </Table>

          {data?.history?.length < 1 ? (
            <div className="d-flex justify-content-center text-center w-100 my-4">
              <NoTableItem queryParams={queryParams} />
            </div>
          ) : null}
        </div>

        <div className="d-flex justify-content-between px-3 align-items-center pagination">
          <p className="m-0">
            Showing {data?.startIndex + 1} to{" "}
            {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
            {data.count} entries
          </p>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              scrollToTop();
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />

          <ModalLoader show={isLoading} />
        </div>
      </Modal.Body>
    </Modal>
  );
}
